@import "../../styles/_variables";

.facilities{
    .filter{
        margin-top: 37px;
        margin-bottom: 18px;
        &__title{
            font-size: 30px;
            display: inline;
            font-weight: 700;
            color: $white;
            padding-right: 30px;
        }
        &__input{
            width: 100%;
        }
        &__text{
            color: $gray-light;
            font-size: 14px;
        }
        .row.filter__row{
            padding-top: 20px;
        }
        &__dropdown{
            border-bottom: $white 2px dashed;
            font-size: 28px;
            color: $white;
        }
        &__button{
            font-size: 28px;
            background: none;
            border: none;
            float: right;
            margin-right: 25px;
            p{
                font-weight: 400;
                color: $gold;
            }
            &:hover{
                p{
                    color: $white;
                }
                cursor: pointer;
            }
        }
    }
}

.modal{
    &__title{
        padding-bottom: 25px;
    }
    &__error {
        width: 100%;
        position: sticky;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $red;
        height: 35px;
        top: 2%;
        border-radius: 5px;
        z-index: 5;
        p {
            text-align: center;
            margin: 10px !important;
            color: white;
            font-size: 18px;
        }
    }
    &__text{
        margin: 0 0 6px 0;
        color: $gray-dark;
        font-size: 14px;
        font-weight: 500;
    }
    &__subtitle{
        font-weight: 600;
        font-size: 20px;
    }
    &__name{
        font-size: 30px;
        font-weight: 600;
        padding-bottom: 42px;
    }
    .ui.grid {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        padding-left: 50px;
        padding-right: 50px;
    }
    &__button{
        margin: 5px;
        padding-bottom: 8px;
        color: $gray-dark;
        font-weight: 600;
        font-size: 16px;
        &:hover{
            cursor: pointer;
            color: $black;
        }
    }
    &__container{
        padding-bottom: 84px;
    }
    p:first-child {
        margin-top: 20px;
    }
    .ui.grid>.row{
        padding-top: 0;
    }
    &__delete{
        padding-top: 35px;
        i{
            color: $gray-dark;
            &:hover{
                color: $black;
                cursor: pointer;
            }
        }
    }
    &__input{
        display: block;
        width: 100%;
    }
    .ui.input>input{
        width: 100%;
    }
    .ui.selection.dropdown{
        min-width: 0;
    }

    /*******************************
                Checkbox
    *******************************/

    /* Handle */

    .ui.toggle.checkbox .box, .ui.toggle.checkbox label{
        font-size: 16px;
        font-weight: 600;
    }

    .ui.toggle.checkbox input:checked~.box, .ui.toggle.checkbox input:checked~label {
        color: $green!important;
    }
    .ui.toggle.checkbox input:focus:checked~.box, .ui.toggle.checkbox input:focus:checked~label{
        color: $green!important;
    }
    .ui.toggle.checkbox .box:after,
    .ui.toggle.checkbox label:after {
        background: #21ba45 -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.05)));
        background: #21ba45 -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
        background: #21ba45 linear-gradient(transparent, rgba(0, 0, 0, 0.05));
        position: absolute;
        content: '' !important;
        opacity: 1;
        z-index: 2;
        border: none;
        -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15),
        0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
        box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15),
        0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
        width: 1.5rem;
        height: 1.5rem;
        top: 0rem;
        left: 0em;
        border-radius: 500rem;
        -webkit-transition: background 0.3s ease, left 0.3s ease;
        transition: background 0.3s ease, left 0.3s ease;
    }

    .ui.toggle.checkbox input:checked ~ .box:before,
    .ui.toggle.checkbox input:checked ~ label:before {
        background-color: #cfcfcf !important;
    }

    .ui.toggle.checkbox input:focus:checked ~ .box:before,
    .ui.toggle.checkbox input:focus:checked ~ label:before {
        background-color: #cfcfcf !important;
    }
}

.modal p:first-child {
    margin-top: 0px;
}

.ui.modal {
    min-height: 380px;
}
