@import "../../styles/_variables.scss";

.navigation{
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    align-items: center;
    &__icon{
        float: right;
    }
    &__logo{
        background: url("../../img/logo.png") no-repeat;
        width: 195px;
        height: 50px;
        margin-bottom: 45px;
    }
    &__text{
        display: block;
        color: $gold;
        font-size: 18px;
        font-weight: 700;
        &:hover{
            color: $white;
        }
        &active{
            color: $white;
        }
    }
    &__list{
        display: block;
        padding-top: 15px;
        color: $gold;
        font-size: 15px;
        font-weight: 700;
        &:hover{
            color: $white;
        }
    }
    &__text.active{
        color: $white;
    }
    &__list.active{
        color: $white;
    }
    .fas.fa-user-tie.fa-lg{
        margin-right: 3px;
    }
    &__button--exit{
        width: 100%;
        display: flex;
        font-size: 18px;
        font-weight: 600;
        background: none;
        border: none;
        justify-content: space-between;
        color: $gray;
        &:hover{
            cursor: pointer;
            color: $white;
        }
    }
    &__hr{
        margin: 20px 0 20px 0;
        min-width: 189px;
        border: 1px $gray-dark solid;
    }
    &__top{
        padding-top: 25px;
    }
    &__bottom{
        padding-bottom: 56px;
    }
}