@import '../../styles/_variables';

.authorization{
    background: $backgraund;
    height: 100vh;
    &__body{
        padding-top: 100px;
        margin: 0 auto;
        width: 585px;
    }

    //                                                              FormAuthorization

    .form_auth{
        height: 500px;
        background: #FFFFFF;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.4);
        border-radius: 0px 0px 10px 10px;
        &__body{
            margin: 0 auto;
            text-align: center;
            width: 450px;
        }
        i.icon {
            margin: 0 18px 0 -7px;
        }
        &__title{
            float: left;
            padding-top: 62px;
            padding-bottom: 30px;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
        }
        &__input{
            height: 72px;
            font-size: 24px;
        }
        &__button{
            width: 100%;
            height: 72px;
        }
        .ui.massive.basic.button.form_auth__button {
             width: 100%;
             height: 72px;
         }
        .ui.massive.basic.disabled.button.form_auth__button {
            border: 1px solid;
        }
        &__error {
            width: 445px;
            position: fixed;
            background: $red;
            top: 40px;
            border-radius: 5px;
            z-index: 50;
            p {
                margin: 10px;
                color: white;
                font-size: 18px;
            }
        }
    }

//                                                                              Header

    .authorization-header {
        background: linear-gradient(211.09deg, #635D58 5.72%, #22211D 84.13%);
        backdrop-filter: blur(14px);
        border-radius: 10px 10px 0 0;
        height: 162px;

        &__body {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding-top: 60px;
        }
        &__logo {
            width: 250px;
            height: 50px;
            background: url('../../img/logo.png') no-repeat;
        }
        &__nav {
            padding-top: 12px;
        }
        &__title {
            font-size: 20px;
            color: $gray-light;
            &:hover{
                color: $gray;
                cursor: pointer;
            }
        }
    }
}