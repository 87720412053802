@import "../../styles/_variables.scss";

.ui.fixed.basic.table.table{
    background: $white;
}
.table{
    i.icon.table__img{
        margin-left: 20px;
        &:hover{
            cursor: pointer;
        }
    }
    &__img{
        float: right;
    }
    .status--cuter{
        width: 13px;
        height: 13px;
        border: 3px solid rgba(167, 167, 167, 1);
        display: inline-block;
        margin-right: 7px;
        border-radius: 50%;
    }
    .status--end{
        width: 13px;
        height: 13px;
        background: rgba(33, 186, 69, 1);
        display: inline-block;
        margin-right: 7px;
        border-radius: 50%;
    }
}